<template>
<div>

  <b-row>
    <b-col sm="12" md="12" lg="6" xl="6">
       <CardServicos/>
    </b-col>
    <b-col md="6" lg="3" xl="3">
      <CardFaturaAtrasada />
    </b-col>
    <b-col md="6" lg="3" xl="3">
      <CardProximaFatura />
    </b-col>

    <b-col sm="12" md="6" lg="6" xl="6">
     <CardServicosFinalizados/>
    </b-col>

<!--    <b-col md="4" lg="3" xl="3">
      <CardUploads/>
    </b-col>-->
    <b-col md="6" lg="6" xl="6">
      <CardAnuncio/>

    </b-col>
  </b-row>
</div>
</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol, } from 'bootstrap-vue'
import CardServicosFinalizados from "./dashboard/CardServicosFinalizados.vue";
import CardServicos from "./dashboard/CardServicos.vue";
import CardFaturaAtrasada from "./dashboard/CardFaturaAtrasada.vue";
import CardUploads from "./dashboard/CardUploads.vue";
import CardAnuncio from './dashboard/CardAnuncio.vue';
import CardProximaFatura from './dashboard/CardProximaFatura.vue';
import {mapGetters, mapState} from "vuex";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,

    CardServicosFinalizados,
    CardServicos,
    CardFaturaAtrasada,
    CardUploads,
    CardAnuncio,
    CardProximaFatura,
  },

  created() {
    if(this.selecionado) {
      this.buscaDashboard()
    }
  },
  computed: {
    ...mapState({
      dashboard: state => state.dashboard.dashboard,
      laboratorios: state => state.laboratorios.laboSelecionado
    }),
    ...mapGetters('laboratorios', ['selecionado']),
  },
  watch: {
    laboratorios(newValue, oldvalue) {
     this.buscaDashboard()
    }
  },
  methods: {
    buscaDashboard() {
      this.$store.dispatch('dashboard/listaDashboard', this.selecionado)
      this.$store.dispatch('chat/listaServicosChat', this.selecionado)
    }
  }
}
</script>

<style>

</style>
